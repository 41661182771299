import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SaleEvent } from '@core/models';
import { BaseConnectionService } from '@core/services/base-connection.service';

export type GetEventListFilters = {
  month: string | null;
  day: string | null;
  opponent: string | null;
};

@Injectable({
  providedIn: 'root',
})
export class ConnectionService extends BaseConnectionService {
  private http = inject(HttpClient);
  eventId: number;
  token: string;

  // Get events list
  // Recupera la lista de eventos para la landing
  getEventsList(page = 1, filters: GetEventListFilters) {
    let endpoint = `${this.apiURL}/events?page=${page}&perPage=20`;

    if (String(filters.month) !== 'null') {
      endpoint += `&month=${filters.month}`;
    }
    if (String(filters.day) !== 'null') {
      endpoint += `&day=${filters.day}`;
    }
    if (String(filters.opponent) !== 'null') {
      endpoint += `&filters[description][$eq]=${filters.opponent}`;
    }

    return this.sendRequest<SaleEvent[]>(this.http, endpoint);
  }

  getOpponents() {
    let endpoint = `${this.apiURL}/events/opponents`;
    return this.sendRequest<string[]>(this.http, endpoint);
  }
}
