import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Pagination } from './models/pagination.model';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnDestroy, OnChanges {
  @Input() totalItemsInDb: number;
  @Input() itemsPerPage: number;
  @Input() currentPage: number;
  @Output() changepage = new EventEmitter<number>();
  public pagination: Pagination;
  private subscriptions: Array<Subscription>;
  private routeService = inject(Router);
  private activatedRoute = inject(ActivatedRoute);

  constructor() {
    this.subscriptions = [];
  }

  ngOnInit(): void {
    this.setPagination(this.currentPage);
  }

  ngOnChanges(changes: SimpleChanges): void {
    let totalItemsInDbCurrent = changes['totalItemsInDb']
      ? changes['totalItemsInDb'].currentValue
      : this.totalItemsInDb;
    let totalItemsInDbPrev = changes['totalItemsInDb']
      ? changes['totalItemsInDb'].previousValue
      : this.totalItemsInDb;
    let itemsPerPage = changes['itemsPerPage']
      ? changes['itemsPerPage'].currentValue
      : this.itemsPerPage;
    let page = changes['currentPage']
      ? changes['currentPage'].currentValue
      : this.currentPage;
    this.refresh(page, totalItemsInDbCurrent, itemsPerPage);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(o => o.unsubscribe());
  }

  public refresh(
    page: number,
    totalItemsInDb: number,
    itemsPerPage: number
  ): void {
    this.totalItemsInDb = totalItemsInDb;
    this.itemsPerPage = itemsPerPage;
    this.setPagination(page);
  }

  public setPagination(pageParam?: number) {
    let current = 1;

    if (pageParam) {
      current = parseInt(pageParam.toString());
    }

    let lastPage = Math.ceil(this.totalItemsInDb / this.itemsPerPage);
    let nextPage = current + 1;
    let previousPage = current - 1;
    if (!previousPage) {
      previousPage = 1;
    }

    if (previousPage < 0) previousPage = 1;

    if (lastPage === current) {
      nextPage = lastPage;
    }
    let minPagesToQuick = 2;
    this.pagination = {
      current: current,
      next: nextPage,
      previous: previousPage,
      last: lastPage,
      quickpages: {
        previous: (Array(minPagesToQuick) as any)
          .fill(0)
          .map((x: number, i: number) => {
            let pagesToAdd = current - (i + 1);
            if (pagesToAdd >= 1) {
              return pagesToAdd;
            }
            return null;
          })
          .sort(),
        next: Array(minPagesToQuick)
          .fill(0)
          .map((x, i) => {
            let pagesToAdd = current + (i + 1);
            if (pagesToAdd >= lastPage) {
              pagesToAdd = lastPage;
            }
            return pagesToAdd;
          }),
      },
    };
    if (this.pagination.quickpages.previous.includes(null as any)) {
      if (this.pagination.current > 1) {
        this.pagination.quickpages.previous = [1];
      } else {
        this.pagination.quickpages.previous = [];
      }
    }
    if (this.pagination.quickpages.next.includes(lastPage)) {
      if (this.pagination.current === this.pagination.last - 1) {
        this.pagination.quickpages.next = [this.pagination.last];
      } else if (this.pagination.current === this.pagination.last) {
        this.pagination.quickpages.next = [];
      }
    }

    let nPrevious = this.pagination.quickpages.previous.length;
    let nNext = this.pagination.quickpages.next.length;
    if (nPrevious < nNext) {
      let diff = nNext - nPrevious;
      if (diff > minPagesToQuick) {
        for (let i = 0; i < diff; i++) {
          let lastNext =
            this.pagination.quickpages.next[
              this.pagination.quickpages.next.length - 1
            ];
          this.pagination.quickpages.next.push(lastNext + 1);
        }
      }
    }
    if (nNext < nPrevious) {
      let diff = nPrevious - current;
      if (diff > 1) {
        for (let i = 0; i < diff; i++) {
          let lastPrev =
            this.pagination.quickpages.previous[
              this.pagination.quickpages.previous.length - 1
            ];
          this.pagination.quickpages.previous.push(lastPrev - 1);
        }
      }
      this.pagination.quickpages.previous.sort();
    }
    this.routeService.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        page: current,
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false,
      // do not trigger navigation
    });
  }

  public goto(page: number) {
    if (this.pagination.current === 1) {
      if (page === 1) return;
    }
    if (this.pagination.current === this.pagination.last) {
      if (page === this.pagination.last) return;
    }

    if (!this.activatedRoute.snapshot.routeConfig) {
      return;
    }

    let currenturl =
      '/private/' + this.activatedRoute.snapshot.routeConfig.path;
    let queryParams = JSON.parse(
      JSON.stringify(this.activatedRoute.snapshot.queryParams)
    );
    queryParams['page'] = page;
    this.routeService.navigate([currenturl], { queryParams: queryParams });
    this.setPagination(Number(page));
    this.changepage.emit(this.pagination.current);
  }
}
